import React from 'react'
// import Footer from './Footer'
import AboutIntro from './HomeComponents/AboutIntro'
import Carousel from './HomeComponents/Carousel'
import ContactShort from './HomeComponents/ContactShort'
import Feedback from './HomeComponents/Feedback'
import ServicesShort from './HomeComponents/ServicesShort'
import WhyWe from './HomeComponents/WhyWe'

const Home = () => {
  return (
    <div>
        <Carousel/>
        <AboutIntro/>
        <ServicesShort/>
        <WhyWe/>
        <Feedback/>
        <ContactShort/>
        {/* <Footer/> */}
    </div>
  )
}

export default Home