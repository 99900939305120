import React from "react";
import { Link } from "react-router-dom";
import About from "../Images/About.jpg";

const AboutIntro = () => {
  return (
    <div>
      <div className="title text-center navbar_bg">
        <h1 className="mb-3">About Us</h1>
      </div>
      <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={About} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3 text-center">
                <p className="lead">
                  The commitment and efforts of providing prompt services has
                  helped us to regularly add and retain valuable client's. With
                  a rich experience along with professional expertise, helped us
                  in creating new standard's for financial planning in India. We
                  have 50+ successful supervised agent and club members in our
                  team along with a high reach on client's and the number's are
                  increasing each day.
                </p>
                <Link to="/about">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutIntro;
