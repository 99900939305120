import React from "react";
import { Link } from "react-router-dom";
import SubService1 from "../Images/SubService1.jpg";

const FinancialPlanning = () => {
  return (
    <div>
      <div className="title text-center navbar_bg my-2">
        <h1 className="mb-2"> Financial Planning</h1>
      </div>
      <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={SubService1} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3">
                <h2 className="text-success">
                  {" "}
                  #Planning is the base of execution
                </h2>
                <p className="texting">
                These days, it's simply not enough to have good investments for retirement-you need to navigate a critical path of financial strategies and decisions that begins years before you retire. This retirement planning guide addresses key issues many face and strategies you can use right now. We make sure to have a comprehensive look at your financial situation and building a specific financial plan to reach your goals.
                </p>
                <h3 className="sub_service_head">
                  Financial Planning Includes:
                </h3>
                <ul>
                  <li className="listing">Forecast Finances</li>
                  <li className="listing">Manage Cashflow</li>
                  <li className="listing">Education Planning</li>
                  <li className="listing">Save and Invest</li>
                  <li className="listing">Insurance</li>
                  <li className="listing">Retirement</li>
                  <li className="listing">Virtual Property</li>
                  <li className="listing">Marriage Planning</li>
                </ul>
                <Link to="/contact">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialPlanning;
