import React from 'react'

const AboutUsIntro = () => {
  return (
    <div>
        <section id="jumbotron">
      <div className="jumbotron jumbotron-fluid bg-warning text-center p-5">
        <div className="container">
          <h1 className="display-4">ABOUT US</h1>
          <h3 className="text-secondary">
            You Trust. We Care. You Prosper.
          </h3>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AboutUsIntro