import React, { useState, useRef } from 'react'
// import emailjs from '@emailjs-com';
import emailjs from 'emailjs-com';

const Form = () => {

  const form = useRef();
  const [annualIncome, setAnnualIncome] = useState('')
  const [occupation, setOccupation] = useState('')


  const handleIncome = (e) => {
    setAnnualIncome(e.target.value)
  }

  const handleOccupation = (e) => {
    setOccupation(e.target.value)
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_x3n5kph', 'template_xn0rfsq', form.current, 'q-MnACvvUEWi5m6Jv')
      .then((result) => {
          console.log(result.text);
          alert('Your response have been submitted')
          window.location.reload()
      }, (error) => {
          console.log(error.text);
          alert('Error Occurred. Please Try Again')
      });
      
  };

  console.log(annualIncome, occupation)

  return (
    <div className="container">
        <div className="row">
          <div className="col">
           <form ref={form} onSubmit={sendEmail} className="bg-warning p-4 mt-5 m-2 form_text">
             <h2> Contact us</h2>
             <p className="form_text">Your data is safe with us. Feel free to contact us and get your doubt, concern or any issue resolved.</p>
             <div className="form-group form_text">
                 <label htmlFor="name">Name:</label>
                 <input type="text" className="form-control" id="name" placeholder="John russel" name="name" required />
               </div>
             <div className="form-group form_text">
               <label htmlFor="email">Email address:</label>
               <input type="email" className="form-control" id="email" placeholder="name@example.com" name='email' required />
             </div>
             <div className="form-group form_text">
                <label htmlFor="contact">Contact number:</label>
                <input type="number" className="form-control" minLength="10" id="contact" name='contact' placeholder="0000000000" required/>
              </div>
              <div className="form-group form_text">
                <label htmlFor="address">Address:</label>
                <input type="text" className="form-control" name='address' id="address" placeholder="Dombivli, Kalyan, Maharashtra, 421201"/>
              </div>
              <div className="form-group form_text">
                <label htmlFor="Source">Occupation:</label>
                <select type="email" name='Source' className="form-control" id="Source" onChange={handleOccupation} required>
                    <option>Business</option>
                    <option>Profession</option>
                    <option>Self-Employeed</option>
                    <option>Others</option>
                </select>
              </div>

              <div className="form-group form_text">
                <label htmlFor="income">Annual Income:</label>
                <select type="email" className="form-control" id="income" name='income' onChange={handleIncome}>
                    <option>Annual Income Below 3,00,000</option>
                    <option>3,00,000 - 5,00,000</option>
                    <option>5,00,000 - 8,00,000</option>
                    <option>15,00,000 + 25,00,000</option>
                    <option>25,00,000 + 50,00,000</option>
                    <option>Above 50,00,000</option>
                </select>
              </div>

             <div className="form-group form_text">
               <label htmlFor="message">Query:</label>
               <textarea className="form-control" id="message" name='query' rows="1" placeholder="Elaborate your concern here." required></textarea>
             </div>
             <button type="submit" className="btn_bg my-2">Submit</button>
           </form>
          </div>
        </div>
      </div>
  )
}

export default Form