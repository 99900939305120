import React from 'react'
import { Link } from 'react-router-dom';
import SubService3 from "../Images/SubService3.jpg";

const Personalised = () => {
  return (
    <div> <div className="title text-center navbar_bg my-2">
    <h1 className="mb-2">Personalised And Customized</h1>
    <h4 className="mb-2">Secure Income And Family</h4>
  </div>
  <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={SubService3} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3">
                <h2 className="text-success">
                  {" "}
                  #Family is asset for life. 
                </h2>
                <p className="texting">
                We understand your concern towards your family and how you wish to give all of world's happiness to them. But, for that, we always insist on taking first step by securing them. We do have a number of plans to help you take your next step right for your family.
                </p>
                <p className="texting">
                Income Protection | Children Education | Kanyadan Policy | Smart Lady | PPF Concepts | Happy Retirement Planning | Term Plan | Virtual Property Concept | Fixed Deposit With Life Long Guaranteed Income
                </p>
                <Link to="/contact">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default Personalised