import React from "react";
import { Link } from "react-router-dom";
import Insurance from "../Images/Insurance.jpg";
import Finance from "../Images/Finance.jpg";
import Mutual from "../Images/Mutual.jpg";

const ServicesShort = () => {
  return (
    <div>
      <div className="title text-center navbar_bg">
        <h1 className="mb-3">Our Services</h1>
      </div>
      <div className="card-group ">
        <div className="card m-2">
          <img
            src={Insurance}
            className="card-img-top card-image img-fluid"
            alt="service"
          />
          <div className="card-body">
            <h5 className="card-title">Insurance Services</h5>
            <p className="card-text">
              One of the primary reasons to have life insurance is providing
              back up of income protection for a family in the event of
              uncertainity.
            </p>
          </div>
        </div>
        <div className="card m-2">
          <img
            src={Finance}
            className="card-img-top card-image img-fluid"
            alt="service"
          />
          <div className="card-body">
            <h5 className="card-title">Financial Planning</h5>
            <p className="card-text">
              Finanicial planning comes in picture when one needs to frame their
              finance properly and live life king size.
            </p>
          </div>
        </div>
        <div className="card m-2">
          <img src={Mutual} className="card-img-top card-image img-fluid" alt="service" />
          <div className="card-body">
            <h5 className="card-title">Mutual Funds</h5>
            <p className="card-text">
              We understand your need of fast returns and we want to provide
              best offers and help you get the better returns at the earliest.
            </p>
          </div>
        </div>
      </div>
      <div className="button_container">
        <Link to="/services">
          <button className="btn btn_bg mx-5 m-2">See More</button>
        </Link>
      </div>
    </div>
  );
};

export default ServicesShort;
