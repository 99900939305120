import React from 'react'
import Objective from '../Images/Objective.jpg'
import Mission from '../Images/Mission.jpg'
import Vision from '../Images/Vision.jpg'

const AboutUsObjectives = () => {
  return (
    <div>
        <section id="vision">
      <h1 className="title text-center navbar_bg my-2">WHAT WE PLAN TO DO FOR YOU?</h1>

      <div className="container-fluid text-center">
          <div className="row">
              <div className="col-md-4">
              <div className="card card-about">
            <img
              src={Objective}
              className="card-img-top img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h1 className="card-title font-weight-bold">Objective</h1>
              <h3 className="text-secondary">Financial Planning</h3>
              <br />
              <p className="card-text font-about">
                Our objective is to guide our customer through the pyramid of
                financial planning so that they can achieve success step by step
                and work on prospering their future life and living standards.
              </p>
            </div>
          </div>
              </div>
              <div className="col-md-4">
              <div className="card card-about">
            <img
              src={Vision}
              className="card-img-top img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h1 className="card-title font-weight-bold">Mission</h1>
              <h3 className="text-secondary">Stable Income And Asset Growth</h3>
              <br />
              <p className="card-text font-about">
                Our mission is that our customer is well planned in thier
                investment so that they can gain income passively along with
                their active income.
              </p>
            </div>
          </div>
              </div>
              <div className="col-md-4">
              <div className="card card-about">
            <img
              src={Mission}
              className="card-img-top img-fluid"
              alt="..."
            />
            <div className="card-body">
              <h1 className="card-title font-weight-bold">Vision</h1>
              <h3 className="text-secondary">Happy And Peaceful Life</h3>
              <br />
              <p className="card-text font-about">
                Our main vision is that our customer lives a peaceful and
                joyfull life in their retirement age without any financial
                stress.
              </p>
            </div>
          </div>
              </div>
          </div>
         
          
       
       
      </div>
    </section>
    </div>
  )
}

export default AboutUsObjectives