import React from "react";

const Carousel = () => {
  return (
    <div className="col-12">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="jumbotron jumbotron-fluid jumbo_bg">
              <div className="container">
                <h1 className="display-4">Financial Planning</h1>
                <p className="lead">
                  We at Shatrunjay financial advisors are always striving to
                  offer our client, service's which we believe to be benefit and
                  of interest to them in ensuring that their life balance
                  continue to operate effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="jumbotron jumbotron-fluid jumbo_bg">
              <div className="container">
                <h1 className="display-4">Mutual Funds</h1>
                <p className="lead">
                  Our commitment towards our client is to deliver the range of
                  service's with the highest standard through which we build the
                  trust and remain best in providing the outstanding results in
                  market.
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="jumbotron jumbotron-fluid jumbo_bg">
              <div className="container">
                <h1 className="display-4">Insurance Services</h1>
                <p className="lead">
                  We believe insurance is the only investment which gives
                  fruitful result whatever the situation is and therefore we
                  never compromise on spreading the importance of insurance and
                  benefit's of it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
