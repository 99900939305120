import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import { blue } from '@mui/material/colors';
import { purple } from '@mui/material/colors';
import { green } from '@mui/material/colors';


const ContactShort = () => {
  return (
    <div>
      <div className="title text-center navbar_bg">
        <h1 className="mb-3"> Social Connects</h1>
      </div>
      <div className='row container text-center'>
      <div className='col'>
        <a href='https://wa.me/9892937740?text=Hello'>
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <WhatsAppIcon fontSize='large' sx={{ color: green[600] }} />
    </Box>
        </a>
        </div>
        <div className='col'>
        <a href=''>
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <LinkedInIcon fontSize='large' sx={{ color: blue[800] }} />
    </Box>
        </a>
        </div>
        <div className='col'> 
        <a href='https://www.facebook.com/MoneyPlantFinancialAdvisors/'>
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <FacebookIcon fontSize='large' sx={{ color: blue[800] }} />
    </Box>
       
        </a>
        </div>
        <div className='col'>
        <a href='https://youtube.com/channel/UCfX8ynJdX1iQDP7Q_YGYxeg'>
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <YouTubeIcon fontSize='large' sx={{ color: red[900] }} />
    </Box>
       
        </a>
        </div>
        <div className=' col'>
        <a href="https://instagram.com/shatrunjayfinancialadvisors?igshid=YmMyMTA2M2Y=">
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <InstagramIcon fontSize='large' sx={{ color: purple[500] }} />
    </Box>
        </a>
        </div>
        <div className='col'>
        <a href="mailto:shatrunjay.fa@gmail.com">
        <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <MailIcon  fontSize='large' sx={{ color: red[500] }} />
    </Box>
        </a>
        </div>
        <div className='col '>
         <a href='tel:9892937740'>
         <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      <CallIcon fontSize='large' sx={{ color: blue[500] }} />
    </Box>
        </a>
      </div>
      </div>
      </div>
  )
}

export default ContactShort