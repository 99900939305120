import React from 'react'
import AboutUsInfo from './AboutComponents/AboutUsInfo'
import AboutUsIntro from './AboutComponents/AboutUsIntro'
import AboutUsObjectives from './AboutComponents/AboutUsObjectives'
import AboutUsTeams from './AboutComponents/AboutUsTeams'


const About = () => {
  return (
    <div>
      <AboutUsIntro/>
      <AboutUsObjectives />
      <AboutUsInfo/>
      <AboutUsTeams/>
    </div>
  )
}

export default About