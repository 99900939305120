import React from 'react'
import { Link } from 'react-router-dom';
import SubService5 from "../Images/SubService5.jpg";

const Creditcards = () => {
  return (
    <div> <div className="title text-center navbar_bg my-2">
    <h1 className="mb-2">Credit Cards</h1>
  </div>
  <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={SubService5} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3">
                <h2 className="text-success">
                  {" "}
                  #Credit Card is the New trend.
                </h2>
                <p className="texting">
                LIC Credit Cards: LIC offers Credit Card powered by AXIS Bank. Platinum Cards & Signature Cards have distinct features. Confirmed Employees, Club Member Agents, & LIC Policy Holders (Income More Than 180000) are eligible. One can have both the cards.
                </p>
                <Link to="/contact">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default Creditcards