import React from "react";
import Fulllogo from "./Images/Fulllogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_main">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              height="200vh"
              width="400vh"
              src={Fulllogo}
              alt="main_logo"
            />
          </div>
          <div className="col-md-6">
            <div className="row text-center">
              <div className="col my-4">
                <h5 className="text_color">
                  <Link
                    className="nav-link text_color active"
                    aria-current="page"
                    to="/services"
                  >
                    Services
                  </Link>
                </h5>
                <h6 className="text_color">
                  <Link
                    className="nav-link text_color active"
                    aria-current="page"
                    to="/services"
                  >
                    Insurance Services
                  </Link>
                </h6>
                <h6 className="text_color">
                  {" "}
                  <Link
                    className="nav-link text_color active"
                    aria-current="page"
                    to="/services"
                  >
                    Financial Planning
                  </Link>
                </h6>
                <h6 className="text_color">
                  <Link
                    className="nav-link text_color active"
                    aria-current="page"
                    to="/services"
                  >
                    Mutual Funds
                  </Link>{" "}
                </h6>
              </div>
              <div className="col my-4">
                <h5 className="text_color">
                  <Link
                    className="nav-link text_color active"
                    aria-current="page"
                    to="/"
                  >
                    Company{" "}
                  </Link>
                </h5>
                <h6 className="text_color">
                  {" "}
                  <Link className="nav-link text_color" to="/about">
                    About
                  </Link>
                </h6>
                <h6 className="text_color">
                  <Link className="nav-link text_color" to="/services">
                    Services
                  </Link>
                </h6>
                <h6 className="text_color">
                  {" "}
                  <Link className="nav-link text_color" to="/contact">
                    Contact
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
