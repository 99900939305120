import React from 'react'
import { Link } from 'react-router-dom'
import General from '../Images/General.jpg'
import NewIndia from '../Images/Partners Images/NewIndia.png'
import IciciLombard from '../Images/Partners Images/IciciLombard.png' 
import TataAig from '../Images/Partners Images/TataAig.png';
import Star from '../Images/Partners Images/Star.jpg';
import Care from '../Images/Partners Images/Care.png';
import HDFCErgo from '../Images/Partners Images/HDFCErgo.webp';
import BajajAllianz from '../Images/Partners Images/BajajAllianz.png'
const GeneralInsurance = () => {
  return (
    <div>
    <div className="title text-center navbar_bg">
      <h1 className="mb-2">General Insurance</h1>
    </div>
    <div className="container-fluid bg-light mb-5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <img src={General} className="img-fluid" alt="About_intro" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <div className="p-3">
              <h2 className="text-success">
                {" "}
                #Safe Surrounding means Safe You.
              </h2>
              <p className="texting">
              There is one famous idiom "Prevention is better than cure". means that it is better to be safe than to be sorry, which is also another idiom that clearly say's that it's better to get the assets covered and have a peaceful breath in loss or damage of assets beacuse you got it covered. 
              </p>
              <p className="texting">
              Types Of General Insurance: Motor Insurance | Health Insurance | Travel Insurance | Home Insurance | Professional Indemnity (Doctor / Chartered Accountant) | Public Liability | Group Gratuity Scheme | Employees Liability (Workmen Compensation) | Director & Officers Liability (IT / MNCS) | Partnership Insurance | Building Insurance | Property Insurance
              </p>
              
              <Link to="/contact">
                <button className="btn btn_bg">See More</button>
              </Link>
             
            </div>
            
          </div>
          <h4 className='container navbar_bg title text-center'>Our Partners</h4>
              <div className='row container'>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={NewIndia} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={IciciLombard} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={TataAig} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={Star} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={Care} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={HDFCErgo} className="img-fluid" height="150px"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={BajajAllianz} className="img-fluid" height="150px"/>
                  </div>
              </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default GeneralInsurance