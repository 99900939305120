import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Form from './Components/Form'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer'


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Form />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
