import React from "react";
import Why from "../Images/Why.jpg";

const WhyWe = () => {
  return (
    <div>
      <div className="title text-center navbar_bg">
        <h1 className="mb-3">Why choose us?</h1>
      </div>
      <div className="container-fluid bg-light mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <img src={Why} className="img-fluid" alt="About_intro" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <div className="p-3">
              <ul className="form_text">
                <li>
                  Giving best services to our clients in every way possible is
                  our primary focus.
                </li>
                <br />
                <li>
                  We are always into learning of soft skills and acquiring new
                  techniques and product knowledge so as to provide our clients
                  the best service's.
                </li>
                <br />
                <li>
                  We believe in showing the right path for finacial freedom to
                  our clients.
                </li>
                <br />
                <li>
                  We believe in building trust and relationship with every
                  prospect clients.
                </li>
                <br />
                <li>
                  We put our best efforts for you because customer satisfaction is our
                  motto.
                </li>
                <br />
                <li>
                  We provide door step service so that it is always convenient
                  for you.
                </li>
                <br />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyWe;
