import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Images/Logo.jpg";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar_bg">
        <div className="container">
          <Link className="navbar-brand text_color" to="/">
            <img
              src={Logo}
              className="img-fluid"
              alt="logo_nav"
              height="40px"
              width="40px"
            /><span className="fs-6 m-1">Shaturnjay <sub>Financial Advisors</sub></span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          {/* New comment */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link
                  className="nav-link text_color active"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text_color" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text_color" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text_color" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
