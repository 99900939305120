import React from 'react'
import Meghna from '../Images/Meghna.jpg'
import Arun from '../Images/Arun.jpg'
import Tej from '../Images/Tej.jpg'

const AboutUsTeams = () => {
  return (
    <div>
        <section id="about">
      <h1 className="title text-center navbar_bg my-2">OUR TEAM</h1>
      <div className="container-fluid">
        <div className="row text-center personal">
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <img
              src={Meghna}
              className="img-fluid"
              alt="Meghna Dhulla"
              height="590px"
              width="600px"
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <div className="container-fluid teams">
              <h2>MEGHNA ARUN DHULLA</h2>
              <p className="text-light ">(Cheif Life Insurance Advisor (C.L.I.A, MDRT))</p>
            </div>
            <p className='font-about'>
              Founder of Shatrunjay Financial Advisors, Meghna Dhulla has more than
              15 years of experience in the field of insurance. She can solve
              any query related to insurance policy in a very short span of
              time. She has a great knowledge in covering every aspect of
              insurance. One of quality that our founder possess is to understand the need and providing the best solutions to our customers. Get your future secured with expert
              guidance and lifetime service guarantee. Always happy to serve.
            </p>
          </div>
        </div>

        <div className="row text-center personal">
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column order-1 p-3"
          >
            <img
              src={Arun}
              className="img-fluid"
              alt="Arun Dhulla"
              height="590px"
              width="600px"
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column order-0 p-3"
          >
            <div className="container-fluid teams">
              <h2>ARUN RAMESH DHULLA</h2>
              <p className="text-light">(MBA in Finance, MDRT)</p>
            </div>
            <p className='font-about'>
              CEO of Shatrunjay Financial Advisors. Arun Dhulla holds a MBA in Finance. He has
              completed a incrediable milestone of providing his service to
              banking sector of our country for 21 golden years. Very
              experienced individual to perfectly guide you through financial
              planning about your investments. Because financial planning is
              very important for stable present and a secure future. His
              knowledge about market and investment can help you fly high in the
              sky like a rainbow. If you wish to fly you know whom to ask.
            </p>
          </div>
        </div>

        <div className="row text-center personal">
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <img
              src={Tej}
              className="img-fluid"
              alt="Tej Dhulla"
              height="600rem"
              width="600rem"
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <div className="container-fluid teams">
              <h2>TEJKUMAR ARUN DHULLA</h2>
              <p className="text-light">(Relationship Manager)</p>
            </div>
            <p className='font-about'>
              All your queries are solved with proper follow up's
              and 100% customer satisfaction. Our main goal is to provide best
              in class service to our customers. This is well achieved under the
              survillance of our Relationship Manager, Tejkumar Arun Dhulla. He takes care of
              all the customers greviances and he is well prepared to solve every
              customers doubts and provide them with satisfactory results.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AboutUsTeams