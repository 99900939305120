import React from 'react'
import { Link } from 'react-router-dom'
import SubService4 from "../Images/SubService4.jpg";

const CorporateBusiness = () => {
  return (
    <div> <div className="title text-center navbar_bg my-2">
    <h1 className="mb-2">Corporate / Business House</h1>
  </div>
  <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={SubService4} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3">
                <h2 className="text-success">
                  {" "}
                  #Employees are Backbone for a successful business.
                </h2>
                <p className="texting">
                Employees are the real asset for any business and we know you care for your employees. We appreciate your efforts and which made us bring a number of plans for your business and employees which will help you make your employee's feel special and keep them secured.
                </p>
                <p className="texting">
                Employer And Employee Policy | Employee Pension Scheme | Group Insurance | Group Gratuity | PPF Concept Scheme | Employee and Employer Scheme | Keyman Insurance | New Group Superannuation Cash Accumulation plan | Loan Liability | 
                  Successive Nomination / Tax Benefit U/S 37 | PPf Schemes For Employees | MWP Act | 
                  Partnership Insurance | LIC Cancer Cover & Health Insurance
                </p>
                <Link to="/contact">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}

export default CorporateBusiness