import React from 'react'
import { Link } from "react-router-dom";
import SubService7 from "../Images/SubService7.jpg";

const Loans = () => {
  return (
    <div>
    <div className="title text-center navbar_bg my-2">
      <h1 className="mb-2">Loans</h1>
    </div>
    <div className="container-fluid bg-light mb-5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <img src={SubService7} className="img-fluid" alt="About_intro" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <div className="p-3">
              <h2 className="text-success">
                {" "}
                #Taking Loans is taking a responsibility.
              </h2>
              <p className="texting">
              We totally understand your issue and needing the emergency funds is hard. which is why we are here to help you get the loans and we deal in three major types of goals such as:
              </p>
              <ul>
                <li className="listing">Home Loans</li>
                <li className="listing">Business Loans</li>
                <li className="listing">Mortgage Loans</li>
              </ul>
              <Link to="/contact">
                <button className="btn btn_bg">See More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Loans