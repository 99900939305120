import React from 'react'
import CorporateBusiness from './Services Components/CorporateBusiness'
import Creditcards from './Services Components/Creditcards'
import FinancialPlanning from './Services Components/FinancialPlanning'
import GeneralInsurance from './Services Components/GeneralInsurance'
import LifeInsurance from './Services Components/LifeInsurance'
import Loans from './Services Components/Loans'
import MutualFunds from './Services Components/MutualFunds'
import Personalised from './Services Components/Personalised'

const Services = () => {
  return (
    <div>
      <FinancialPlanning/>
      <LifeInsurance/>
      <Personalised/>
      <CorporateBusiness/>
      <GeneralInsurance/>
      <Creditcards/>
      <MutualFunds/>
      <Loans/>
    </div>
  )
}

export default Services