import React from "react";
import ReviewFirst from "../Images/ReviewFirst.mp4";
import ReviewSecond from "../Images/ReviewSecond.mp4";

const Feedback = () => {
  return (
    <div>
      <div className="title text-center navbar_bg">
        <h1 className="mb-3">Success Stories</h1>
      </div>
      <div className="card-group ">
        <div className="card m-2 video_card">
          <video src={ReviewFirst} controls className="video" />
          <div className="card-body">
            <h5 className="card-title">Jitendra Dharamshi</h5>
            <p className="card-text">
              A heart whelming feedback from one of our client Mr. Jitendra
              Dharamshi and we wish him all the very best for all his future
              endeavors.
            </p>
            <div className="video_date">
              <small> Updated: 12 December, 2021</small>
            </div>
          </div>
        </div>
        <div className="card m-2 video_card">
          <video src={ReviewSecond} controls className="video" />
          <div className="card-body">
            <h5 className="card-title">Nilam Nagda</h5>
            <p className="card-text">
              A most appreciated feedback from one of our client Mrs. Nilam
              Nagda and we are really greatful and motivated to get such a positive response.
            </p>
            <div className="video_date">
              <small> Updated: 12 December, 2021</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
