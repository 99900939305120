import React from "react";
import { Link } from "react-router-dom";
import SubService6 from "../Images/SubService6.png";

const MutualFunds = () => {
  return (
    <div>
      <div className="title text-center navbar_bg my-2">
        <h1 className="mb-2">Mutual Funds</h1>
      </div>
      <div className="container-fluid bg-light mb-5">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <img src={SubService6} className="img-fluid" alt="About_intro" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
              <div className="p-3">
                <h2 className="text-success">
                  {" "}
                  #Mutual Investment is a better way Investment.
                </h2>
                <p className="texting">
                  Someone well said that "Anytime is a good time for SIP". Past
                  performance of mutual fund does not affect its current and
                  future Growth. Therefore, mutual funds are always a great
                  option for investment. There are multiple types of mutual
                  funds such as:
                </p>
                <ul>
                  <li className="listing">Money Market Funds</li>
                  <li className="listing">Bond Funds</li>
                  <li className="listing">Equity Funds</li>
                  <li className="listing">Balanced Funds</li>
                  <li className="listing">Equity Index Funds</li>
                </ul>
                <p className="texting">
                  Mutual Fund have been one of the best investment for every
                  individuals and it gives the better returns only if you got a proper financial planner by your side. DISCLAIMER: Mutual funds investment are subject to
                  market risks. Read all scheme related documents carefully.
                </p>

                <Link to="/contact">
                  <button className="btn btn_bg">See More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MutualFunds;
