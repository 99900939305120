import React from 'react'
import { Link } from "react-router-dom";
import SubService2 from "../Images/SubService2.jpg";
import HdfcLife from '../Images/Partners Images/HdfcLife.png'
import ICICILife from '../Images/Partners Images/ICICILife.png'
import LicLogo from '../Images/Partners Images/LicLogo.png'
import TataLife from '../Images/Partners Images/TataLife.png'
import SBILife from '../Images/Partners Images/SBILife.webp'

const LifeInsurance = () => {
  return (
    <div>
    <div className="title text-center navbar_bg">
      <h1 className="mb-2">Life Insuarance</h1>
    </div>
    <div className="container-fluid bg-light mb-5">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <img src={SubService2} className="img-fluid" alt="About_intro" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <div className="p-3">
              <h2 className="text-success">
                {" "}
                #Life insurance is must
              </h2>
              <p className="texting">
              Life insurance provides financial protection for your family in the event of your passing. Your beneficiaries will receive money to use as they see fit, ensuring security in a difficult time. And as KIN HUBBARD mentioned, "Fun is like life insurance; The older your get, The more it costs".
              </p>
              <h3 className="sub_service_head">
              Other Benefits:
              </h3>
              <ul>
              <li className="listing">Tax Benefit U/S 80C </li>
                      <li className="listing">After 2 Years Emergency Funds</li>
                      <li className="listing">Riskfree - No Stock Market, No RBI Rule</li>
                      <li className="listing">TAX FREE Maturity U/S 10(10)D</li>
                      <li className="listing">Mortgage, Cash Credit & Overdraft Facility</li>
                      <li className="listing">Central Government Sovereign Guarantee</li>
              </ul>
              <Link to="/contact">
                <button className="btn btn_bg">See More</button>
              </Link>
             
            </div>
            
          </div>
          <h4 className='container navbar_bg title text-center'>Our Partners</h4>
              <div className='row container'>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={LicLogo} className="img-fluid" height="150px" alt="LIC_LOGO"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={HdfcLife} className="img-fluid" height="150px" alt="HDFC_LOGO"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={ICICILife} className="img-fluid" height="150px" alt="ICIC_LOGO"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={SBILife} className="img-fluid" height="150px" alt="SBI LOGO"/>
                  </div>
                  <div className='col d-flex justify-content-center align-items-center flex-column'>
                      <img src={TataLife} className="img-fluid" height="150px" alt="TATA_LOGO"/>
                  </div>
              </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default LifeInsurance