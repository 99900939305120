import React from 'react'
import Fulllogo from '../Images/Fulllogo.png'

const AboutUsInfo = () => {
  return (
    <div>
        <section id="advisors">
      <h1 class="title text-center navbar_bg my-2">ABOUT SHATRUNJAY FINANCIAL ADVISORS</h1>
      <div class="container-fluid text-center p-2">
        <div class="row text-center p-3">
          <div
            class="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <img
              src={Fulllogo}
              alt=""
              class="img-fluid"
            //   height="590px"
            //   width="600px"
            />
          </div>
          <div
            class="col-md-6 d-flex align-items-center justify-content-center flex-column p-3"
          >
            <p className='font-about'>
              The commitment and endeavors to prompt services has helped us to
              regularly add and retain valuable client's. With a rich experience
              combined with the best in class professional expertise, Shatrunjay
              Financial Advisor has created new standard's for financial
              planning in India. We have brought together a powerful combination
              of the real world experience of our professional's wide reach,
              proven processes and a strong commitment to integrity everything aimed
              at delivering extraordinary service to our client. We have 50+
              successful supervised agent and club members in our team.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AboutUsInfo